class Point {
  constructor(container) {
    this.container = container
    this.button = container.querySelector('.j-menu-title')
    this.submenu = container.querySelector('.j-menu-submenu')
    this.items = document.querySelectorAll('.j-menu-item')
    this.link = container.querySelector('.j-menu-link')

  }
  init() {
    this.button.onclick = () => {
      var $this = this;
      if (this.submenu.classList.contains('_open')) {
        this.submenu.classList.remove('_open')
        this.button.classList.remove('_open')
        this.container.classList.remove('_open')
        this.link.classList.remove('_open')
      }else {
        this.closeOpenBlocks()
        this.submenu.classList.add('_open')
        this.button.classList.add('_open')
        this.container.classList.add('_open')
        this.link.classList.add('_open')
        var $this = this;
        setTimeout(function() {
          $this.button.scrollIntoView({block: "start", behavior: "smooth"})
        },300)
      }
    }
  }
  closeOpenBlocks() {
    this.items.forEach(el => {
      if (el.querySelector('.j-menu-submenu')) {
        el.querySelector('.j-menu-submenu').classList.remove('_open')
        el.querySelector('.j-menu-title').classList.remove('_open')
        el.querySelector('.j-menu-link').classList.remove('_open')
        el.classList.remove('_open')
      }
    })
  }
}
export default Point