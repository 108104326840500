import Menu from './menu/mobile-menu'
import MenuPoint from './menu/point'

document.addEventListener('DOMContentLoaded', function () {
  console.log("start")
  let NewMenu = new Menu()
  NewMenu.init()


  document.querySelectorAll('.j-menu-item')
  .forEach(block => {
    let NewMenuPoint = new MenuPoint(block)
    NewMenuPoint.init()
  })

  const sliderBanner = new Swiper('.j-slider-banner', {
    spaceBetween: 16,
  
    breakpoints: {
      768: {
        spaceBetween: 40,
      }
    },
    navigation: {
      prevEl: '.j-banner-arrow-prev',
      nextEl: '.j-banner-arrow-next',
    },
  });

  const sliderProject = new Swiper('.j-project-slider', {
    slidesPerView: 1.05,
    pagination: {
      el: '.swiper-pagination',
    },
    spaceBetween: 20,
    breakpoints: {
      768: {
        slidesPerView: 2.03,
        spaceBetween: 25,
      },
      1280: {
        slidesPerView: 4,
        spaceBetween: 28,
      }
    },
    navigation: {
      prevEl: '.j-project-arrow-prev',
      nextEl: '.j-project-arrow-next',
    },
  });

  const sliderAnons= new Swiper('.j-anons-slider', {
    slidesPerView: 1.61,
    setWrapperSize: true,
    pagination: {
      el: '.anons-pagination',
      clickable: true,
    },
    spaceBetween: 24,
    breakpoints: {
      1280: {
        slidesPerView: 4,
        spaceBetween: 28,
      }
    },
  });

  const sliderLive= new Swiper('.j-live-slider', {
    slidesPerView: 1.7,
    setWrapperSize: true,
    pagination: {
      el: '.live-pagination',
      clickable: true,
    },
    autoHeight: true,
    spaceBetween: 24,
    breakpoints: {
      768: {
        slidesPerView: 1.6,
      }
    },
  });

  const sliderNews= new Swiper('.j-news-slider', {
    slidesPerView: 1.7,
    setWrapperSize: true,
    pagination: {
      el: '.news-pagination',
      clickable: true,
    },
    spaceBetween: 24,
    breakpoints: {
      768: {
        slidesPerView: 1.6,
      }
    },
  });

})