class Menu {
  constructor() {
    this.button = document.querySelector('.j-menu-but')
    this.menuMobile = document.querySelector('.j-mobile-menu')
    this.header = document.querySelector('.j-header')
    this.scrollMenuContainer  = document.querySelector('.j-menu-scroll')

  }
  init() {
    var $this = this;
    
    $this.menuMobile.addEventListener('scroll', function(e) {
      if($this.menuMobile.scrollTop > 0){
        $this.header.classList.add('_scrolling')
      } else {
        if($this.header.classList.contains('_scrolling')) {
          $this.header.classList.remove('_scrolling')
        }
      }
    });
    $this.scrollMenuContainer.addEventListener('scroll', function(e) {
      if($this.scrollMenuContainer.scrollTop > 0){
        $this.header.classList.add('_scrolling')
      } else {
        if($this.header.classList.contains('_scrolling')) {
          $this.header.classList.remove('_scrolling')
        }


      }
    });
    this.button.onclick = () => {
        
      if (this.button.classList.contains('_open')) {
        this.button.classList.remove('_open')
        this.menuMobile.classList.remove('_open')
        this.header.classList.remove('_open-menu')
        document.body.style.overflow = 'auto';

      }else {
        this.menuMobile.scroll(0,0)
        this.button.classList.add('_open')
        this.menuMobile.classList.add('_open')
        this.header.classList.add('_open-menu')
        document.body.style.overflow = 'hidden';

      }
    }

  }
}
export default Menu